/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2022-12-12 10:49:35
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-12-13 10:57:05
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from "element-plus"
import "element-plus/dist/index.css"

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
import * as echarts from 'echarts'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(ElementPlus)
app.use(echarts)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)

app.$echarts = echarts

app.mount('#app')
